import {
  AlertColor,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  Slide,
  Snackbar,
} from '@mui/material'
import React from 'react'

export interface AlertProps {
  severity: AlertColor | undefined
  message: string
  open: boolean
  onClose?: () => void
}
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />
}

export type AlertCustomProps = AlertProps & MuiAlertProps

const Alert: React.FC<React.PropsWithChildren<AlertCustomProps>> = ({
  severity,
  message,
  open,
  onClose,
}) => {
  return (
    <Snackbar
      TransitionComponent={TransitionLeft}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MuiAlert
        onClose={onClose}
        severity={severity || 'info'}
        variant="filled"
        sx={{ width: '100%' }}
        aria-label="alert-info"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
